interface props{
    isPrivateChat?:boolean;
    lightTheme?:boolean;

}
export const RoleBotIcon=({isPrivateChat, lightTheme}:props)=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="27" viewBox="0 0 22 27" fill="none">
  <path d="M1.96255 25.9979L16.5078 19.7983L20.3934 6.88079L11.2566 1.12254L1.27971 10.2012L1.96255 25.9979ZM1.96255 25.9979L8.89378 14.9998M8.89378 14.9998C9.7348 15.5298 10.8599 15.256 11.4068 14.3883C11.9536 13.5206 11.7152 12.3875 10.8741 11.8574C10.0331 11.3274 8.908 11.6012 8.36114 12.4689C7.81428 13.3366 8.05275 14.4697 8.89378 14.9998Z" stroke={isPrivateChat?"#333C7A": lightTheme?"#7C4DFF" :"#9C97AC"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    )
}