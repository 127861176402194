import { useEffect, useState, memo } from "react";
import styles from "./ChatItem.module.scss";
import { FormattedMessage } from "react-intl";
import { PriceLoadMoreIcon } from "pages/LandingPage/components/Icons";
import { WordHighlighter } from "./WordHighlighter";

import { useSelector } from "redux/hooks";
import { Tooltip } from "components";

import {
  IChat,
  setScrollId,
  setSelectesMessageId
} from "redux/actions";
interface IProps {
  chatItem: IChat;
  onSelectChat?: (itemId: IChat | undefined) => void;
  searchQuery: string;
}

export const ChatSearchItem = memo(
  ({ chatItem,  onSelectChat, searchQuery }: IProps) => {
    const [isShowMore, setIsShowMore] = useState<boolean>(false);
    const MAX = 3;
    const {scrollId } = useSelector(
      (state) => state.chatReducer
    );
    useEffect(() => {
      if (chatItem.searched_messages) {
        setIsShowMore(chatItem.searched_messages?.length > MAX);
      }
    }, [chatItem.searched_messages]);

    const themeMode = useSelector((state) => state.authReducer.theme);
    return (
      <div data-testid='search-chat-btn' onClick={() => onSelectChat?.(chatItem)}>
        <div className="flex flex-col ml-[32px] text-prompt-dark whitespace-nowrap overflow-hidden text-ellipsis text-opacity-90">
          {chatItem?.searched_messages &&
            chatItem.searched_messages.map(
              (message, index) =>
                ((isShowMore && index < MAX) || !isShowMore) && (
                  <Tooltip
                    control={
                      <div
                        className={`${"flex flex-col text-[12px] leading-7 pl-[46px] pr-2 py-1.5 cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis"}  ${themeMode === "dark"? "px-2":"px-2"} ${themeMode === "dark" ? styles.itemContainerDark : styles.itemContainerLight}`}

                        key={index}
                        onClick={() => {
                          setSelectesMessageId(message?.id ??0)
                          setScrollId(!scrollId)
                        }}
                      >
                        <WordHighlighter
                          searchQuery={searchQuery}
                          text={String(message?.content)}
                          excerptLength={24}
                        />
                      </div>
                    }
                    placement="right"
                    theme="light"
                  >
                    {chatItem.topic || "New Chat"}
                  </Tooltip>
                )
            )}
        </div>
        <div
          className="flex flex-col ml-[32px] text-prompt-dark whitespace-nowrap overflow-hidden text-ellipsis text-opacity-90">
          {isShowMore && (
            <span
              data-testid='sidebar-chat-view-more'
              className="flex items-center gap-1 pl-[46px] cursor-pointer text-sm mt-2 pr-1"
              onClick={(e) => {
                setIsShowMore(false)
                e.stopPropagation();
              }}
            >
              <PriceLoadMoreIcon viewMore={true} themecolor={themeMode} />
              <FormattedMessage id="sidebar.chat.view.more" />
            </span>
          )}
        </div>
      </div>
    );
  }
);