import { Dispatch, Fragment, memo, SetStateAction, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";
import styles from "./SettingMiniSidebar.module.scss";

import {
    AccountIcon,
    CurrentPlanIcon,
    TeamIcon,
    FeedbackIcon,
    EarnAsAffiliateIcon,
} from "../icons";

import { Separator } from "components";
import { Credits } from "views/layout/Navbar/components/ChatNav/components/Credits";
import { ThemeSwitch } from "../../../ChatSidebar/components/ThemeSwitch";

import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";
import { useSelector } from "redux/hooks";
import { SettingIcon } from "../../../ChatSidebar/components/icons";
import { DeleteHistory } from "../../../ChatSidebar/components/icons/DeleteHistory";
import { deleteAllChats, deleteAllConversation, setPagination } from "redux/actions";
import { DeleteChatConfirm } from "../../../ChatSidebar/components/DeleteChatConfirm";
import { useAppNotification } from "hooks/services/AppNotification";

interface IProp {
    setChatHistory?: Dispatch<SetStateAction<any>>;
  }

export const SettingMiniSidebar = memo(({setChatHistory}: IProp) => {

    const {triggerNotification} = useAppNotification();
    const { userDetail, theme } = useSelector((state) => state.authReducer);
    const { includeRoute } = useRouter();
    const [showConfirmModel, setShowConfirmModel] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const itemStyles = (route: string) => {
        return classNames(styles.menuItem, {
            [styles.active]: includeRoute(route),
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        setChatHistory?.([]);
        deleteAllChats();
        setPagination(0, 0)
        setShowConfirmModel(false);
        setLoading(false);
        deleteAllConversation().then(() => {
          setShowConfirmModel(false);
          setLoading(false);
        }).catch((err) => {
          setShowConfirmModel(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
        })
      }

    return (
        <Fragment>
            {showConfirmModel &&
                <DeleteChatConfirm onCancel={() => setShowConfirmModel(false)} onSubmit={handleSubmit} loading={loading} delConversation={true} />
            }
            <div className={styles.hideCredits}>
                <Credits settingMiniBar={true} />
            </div>
            <Separator height="20px" />
            <div
                className={classNames(styles.container, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                })}
            >
                <div className={styles.memu}>
                    <div className={classNames(styles.icon, {
                        // [styles.light]: theme === 'light',
                    })}>
                        <SettingIcon />
                    </div>
                    <NavLink
                        data-testid='setting-side-bar-account-btn'
                        className={itemStyles(RoutePaths.Account)}
                        to={RoutePaths.Account}
                        style={{ marginTop: '5px' }}
                    >
                        <AccountIcon route={includeRoute(RoutePaths.Account)} />
                    </NavLink>
                    {userDetail?.user.team.role !== "member" && (
                        <>
                            <NavLink
                                data-testid='setting-side-bar-team-btn'
                                className={itemStyles(RoutePaths.Team)}
                                to={RoutePaths.Team}
                            >
                                <TeamIcon route={includeRoute(RoutePaths.Team)} />
                            </NavLink>
                            {userDetail?.user.team.role === "admin" && (
                                <NavLink
                                    data-testid='setting-side-bar-current-btn'
                                    className={itemStyles(RoutePaths.CurrentPlan)}
                                    to={RoutePaths.CurrentPlan}
                                >
                                    <CurrentPlanIcon theme={theme} route={includeRoute(RoutePaths.CurrentPlan)} settingsidebar={true} />
                                </NavLink>
                            )}
                        </>
                    )}
                    <a
                        href="https://deftgpt.canny.io/feature-requests/create"
                        target="_blank"
                        rel="noreferrer"
                        className={itemStyles(RoutePaths.Feedback)}
                        data-testid='setting-side-bar-feedback-btn'
                    >
                        <FeedbackIcon />
                    </a>

                    <Link
                        data-testid='setting-side-bar-affiliate-btn'
                        to={`/${RoutePaths.Affiliate}`}
                        className={itemStyles(RoutePaths.Affiliate)}
                    >
                        <EarnAsAffiliateIcon />
                    </Link>
                    {/* {includeRoute(RoutePaths.Account) && */}
                        <div
                            data-testid='setting.del.all.chat'
                            className={itemStyles(RoutePaths.Account)}
                            onClick={() => setShowConfirmModel(true)}
                        ><DeleteHistory settingSideBar={true} />
                        </div>
                    {/* } */}
                </div>
            </div>
            <div
                style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                }}
            >
                <ThemeSwitch minSidebar={true} />
            </div>
        </Fragment>
    );
});
