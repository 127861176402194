import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormattedMessage } from "react-intl";

import styles from "./answerContainer.module.scss";
import messageStyles from "../message/message.module.scss";
import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";

import { WordHighlighter } from "views/layout/Sidebar/component/ChatSidebar/components/ChatItem/WordHighlighter";
import { CopyIcon } from "../icons";

import ChatEditIcon from "../icons/ChatEditIcon";
import { useSelector } from "redux/hooks";

import { IMessage, IShareChatMessage } from "redux/actions";
import React, { RefObject } from "react";
import { Tooltip } from "components";

interface IProps {
    message?: IMessage;
    shareMessage?: IShareChatMessage;
    shareChat?: boolean;
    searchQuery?: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    isEditMode: boolean;
    textAreaRef: RefObject<HTMLTextAreaElement>;
    isShareChat: boolean;
    setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
    isHighlighted: boolean;
    setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    isCopied: boolean;
    handleKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export const QuestionContainer = ({
    message,
    searchQuery,
    shareMessage,
    shareChat,
    setMessage,
    isEditMode,
    textAreaRef,
    isShareChat,
    setIsCopied,
    isHighlighted,
    setIsEditMode,
    isCopied,
    handleKeyDown
}: IProps) => {

    const { theme } = useSelector((state) => state.authReducer);

    return (
        <div className={` 
    ${styles.messageContainer} ${((message?.files?.length ?? 0) > 0 || (message?.images?.length ?? 0) > 0 || (shareMessage?.images?.length ?? 0) > 0 || (shareMessage?.files?.length ?? 0) > 0) ? styles.additionalClass : ''} ${shareMessage?.content && (shareMessage.images && shareMessage.images.length > 0 || shareMessage.files && shareMessage.files.length > 0) ? styles.sharedimage : ''}`}>
            <p
                className={classNames(messageStyles.message, {
                    [messageStyles.light]: theme === "light",
                    [messageStyles.dark]: theme === "dark",
                })}
            >
                {isEditMode ? (
                    <div className="grid flex-1">
                        <TextareaAutosize
                            ref={textAreaRef}
                            style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                width: "100%",
                                background: "transparent",
                                flexGrow: 1,
                                border: "none",
                                outline: "none",
                                resize: "none",
                                boxSizing: "border-box",
                            }}
                            className={classNames(messageStyles.message, {
                                [messageStyles.light]: theme === "light",
                                [messageStyles.dark]: theme === "dark",
                                [messageStyles.styleTextArea]: true,
                            })}
                            autoFocus
                            defaultValue={
                                message ? message?.content : shareMessage?.content
                            }
                            onChange={(e) => {
                                e.preventDefault();
                                setMessage && setMessage(e.target.value);
                            }}
                            onKeyDown={(e) => handleKeyDown(e)}
                        />
                    </div>
                ) : (
                    <div className={messageStyles.mainMessage}>
                        <div
                            className={classNames(messageStyles.userMessage, {
                                [messageStyles.light]: theme === "light",
                                [messageStyles.dark]: theme === "dark",
                                [messageStyles.sharchat]: isShareChat,
                                [messageStyles.messageContent]:isShareChat &&!(shareMessage?.files),
                                [messageStyles.mainQuestion]:true
                            })}
                            // style={{ maxWidth: "73%", width: "auto" }}
                        >
                            <div className={styles.messageContent}>
                                {isHighlighted ? (
                                    <WordHighlighter
                                        searchQuery={searchQuery || ""}
                                        text={String(
                                            message
                                                ? message?.content
                                                : shareMessage?.content
                                        )}
                                        excerptLength={0}
                                    />
                                ) : (
                                    <>
                                        {message ? (
                                            <div>{message?.content}</div>
                                        ) : (
                                            shareMessage?.content
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        {!shareChat && (

                            <div className={styles.EditButton}>
                                <Tooltip
                                    control={
                                        <CopyToClipboard
                                            text={String(message?.content)}
                                            onCopy={() => setIsCopied(true)}
                                        >
                                            <div className={`${styles.copyIcon} ${"cursor-pointer flex justify-center items-center"}`}
                                            >
                                                <CopyIcon />
                                            </div>
                                        </CopyToClipboard>
                                    }
                                    placement="top"
                                    theme="light"
                                >
                                    <div>
                                        <FormattedMessage
                                            id={
                                                isCopied
                                                    ? "answer.tooltip.copied"
                                                    : "answer.tooltip.copy"
                                            }
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                        {!shareMessage?.content && (
                            <div className={styles.EditButton}>
                                <Tooltip
                                    control={
                                        <div
                                            className={`${styles.editIcon} ${"cursor-pointer flex justify-center items-center"}`}
                                            onClick={() => setIsEditMode(!isEditMode)}
                                        >
                                            <ChatEditIcon />
                                        </div>
                                    }
                                    placement="top"
                                    theme="light"
                                >
                                    <div>
                                        <FormattedMessage id={"answer.tooltip.edit"} />
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                )}
            </p>
        </div>
    );
};
