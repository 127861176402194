import { IChatModel } from "../chatModels";

export interface ISendMessageBody {
    message: string;
    newChatBoolean: boolean;
    chatId: number | null;
    model: string;
    chatType?: EChatType;
    messageViewType?: EMessageViewType;
    file_path?: string[];
    regenerate?: boolean;
    images?: string[];
    isPrivate?: boolean;
  }
  
  export interface IGenerateRelatedQuestionsBody {
    chatId: number | null;
    messageViewType?: EMessageViewType;
  }
  
  export interface IRemoveGeneratedQuestions {
    messageId: number;
    messageViewType?: EMessageViewType;
  }
  
  export interface IGetAllChat {
    chats: IChat[];
  }
  
  export interface IGetChatHistory {
    messages: IMessage[];
  }
  
  export type EMessageViewType =
    (typeof MessageViewType)[keyof typeof MessageViewType];
  
  export const MessageViewType = {
    new: "new",
    history: "history",
  } as const;
  
  export type EChatType = (typeof ChatType)[keyof typeof ChatType];
  
  export const ChatType = {
    text: "text",
    document: "document",
    image: "image",
    image_chat: "image_chat",
  } as const;
  
  export type EMessageType = (typeof MessageType)[keyof typeof MessageType];
  
  export const MessageType = {
    user: "user",
    assistant: "assistant",
  } as const;
  
  export interface IFile {
    id: number | null;
    model_type?: string;
    model_id: number | null;
    name: string;
    path: string | null;
    created_at: string | null;
    updated_at: string | null;
  }
  
  export interface IUpscaledImage {
    id?: number;
    image_id: number;
    upscale_image_id: number;
    created_at?: string;
    updated_at?: string;
  }
  
  export interface IImage {
    id: number;
    model_type: string;
    model_id: number;
    name: string | null;
    path: string;
    size: string;
    created_at: string;
    updated_at: string;
    upscaled_images?: IUpscaledImage[];
  }
  
  export interface ISharImgeChat {
    path?: string;
  }
  
  export interface IChat {
    id: number;
    chat_type: EChatType;
    topic: string;
    is_topic_updated?: number | null;
    document_index_name?: string;
    user_id: number;
    created_at?: string;
    updated_at?: string;
    files?: IFile[];
    last_assistant_message?: IMessage;
    searched_messages?: IMessage[];
    model?: IChatModel;
    messages?: IMessage[];
  }
  
  export interface IMessage {
    id?: number;
    content?: string;
    images: IImage[];
    type?: EMessageType;
    chat_id?: number;
    created_at?: string;
    updated_at?: string;
    isNew?: boolean;
    related_questions?: string[];
    files?: IFile[];
    regenerate_model_id?: number | null;
  }
  
  export interface IShareChatMessage {
    content?: string;
    type?: EMessageType;
    images: ISharImgeChat[];
    files?: IFile[];
    // 0: ImageContainer;
  }
  
  export interface IStopGeneratingMessageBody {
    chatId?: number;
    messageId?: number;
  }
  
  export interface ISaveFilePathBody {
    path: string;
    name: string;
  }
  
  export interface IChatHistoryResponse {
    chat: IChat;
    messages: IMessage[];
  }
  
  export interface IPrompt {
    id: number;
    title: string;
    prompt: string;
    created_at: string;
    updated_at: string;
  }
  
  export interface ISavePrompt {
    title: string;
    prompt: string;
  }
  
  export interface IGetChat {
    token: string;
  }
  
  export interface IShareChat {
    id: number;
    chat_id: number;
    model_id: number;
    chat_type: string;
    token: string;
    messages: IShareChatMessage[];
    created_at: string;
    updated_at: string;
  }
  
  export interface IChatSetting {
    real_time_results: boolean;
    related_questions: boolean;
    send_message_with_enter: boolean;
    customized_response?: boolean;
  }

  export interface ChatSetting {
    key: string;
    real_time_results: boolean;
    related_questions: boolean;
    send_message_with_enter: boolean;
    customized_response?: boolean;
  }

  export interface RememberBot {
    key: string;
    customized_response: boolean;
  }

  export interface IRollBotSetting {
    language: string;
    length: string;
    output_format: string;
    style_of_writing: string;
    tone: string;

}
  
  export interface IBot {
    key: string;
    output_format?: string;
    tone?: string;
    style_of_writing?: string;
    length?: string;
    language?: string;
  }

  export interface subOptions {
    label: string;
    value: string;
  }
 export interface BotSettings {
    writingStyles: subOptions[];
    languages: subOptions[];
    outputFormats: subOptions[];
    responseLengths: subOptions[];
    tones: subOptions[];
  }

  export interface SettingsBase {
    chat_settings: IChatSetting;
    bot_settings: IRollBotSetting;
    bot_settings_allowed: BotSettings;
  }

  export interface subOption {
    label: string;
    value: string;
  }
  
  export interface DropdownOption {
    key: string,
    label: string;
    value: string;
    options: subOption[];
  }

  export interface pagination {
    page: number;
    lastPage: number;
  }
  