import React from "react";
// import styles from "../../chatFooter.module.scss";
import styles from "./credit.module.scss";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import classNames from "classnames";
import { useSelector } from "redux/hooks";
import { FormattedMessage } from "react-intl";

interface creditDeduction{
credit: number;
}

export const CreditDeduction: React.FC<creditDeduction> = ({
  credit
}) => {
    const { theme } = useSelector((state) => state.authReducer);
    const { isOpen } = useToggleSidebar();
    const { PrivateChat} = useSelector((state) => state.chatReducer);

    return (
        <>
            <div
                className={classNames(styles.IGOptionContainer, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                    [styles.isSidebarOpen]: isOpen,
                    [styles.isPrivateChat]:PrivateChat,
                    
                })}
                style={{ justifyContent: 'flex-end' }}
            >
                <div className={styles.imageCredits} style={{fontWeight:'400'}}>
                    <FormattedMessage id={Number(credit) === 1 ? "chat.image.one.credit" : "chat.image.credit.perQuery"}
                        values={{ credits: Number(credit) }}
                    />
                </div>
            </div>
        </>
    );
};
